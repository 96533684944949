<template>
  <div class="change-details">
    <Content>
      <ConfirmUserDetailsForm></ConfirmUserDetailsForm>
    </Content>
  </div>
</template>

<script>
import ConfirmUserDetailsForm from '@components/UserDetailsComponents/ConfirmUserDetailsForm.vue';

export default {
  name: 'ConfirmUserDetails',
  components: {
    ConfirmUserDetailsForm,
  },
};
</script>

<style scoped>

</style>
