<template>
  <div class="change-details-form">
    <LoadingAllScreen :is-loading.sync="loading" :scroll-lock="false"/>
    <div v-if="!showChangeData">
      <h1 class="h4 text-center mb-6">Confirm the following</h1>
      <form class="form">
        <!-- <BaseInput
          class="form-group"
          placeholder="Previous phone number (inc Country Code)"
          type="phone"
          :hasError="$v.formData.previousPhoneNumber.$error"
          v-model="formData.previousPhoneNumber"
          :disabled="showInputQuestion"
        >
          <template slot="error-message">
            <span v-if="!$v.formData.previousPhoneNumber.required">Field is required</span>
          </template>
        </BaseInput> -->
        <BaseInput
          class="form-group"
          placeholder="Email"
          :hasError="$v.formData.email.$error"
          v-model="formData.email"
          :disabled="showInputQuestion"
        >
          <template slot="error-message">
            <span v-if="!$v.formData.email.required">Field is required</span>
            <span v-else-if="!$v.formData.email.mustBeCool">Check correct email</span>
          </template>
        </BaseInput>
        <BaseInput
          class="form-group"
          placeholder="First name Last name"
          :hasError="$v.formData.firstNameAndLastName.$error"
          v-model="formData.firstNameAndLastName"
          :disabled="showInputQuestion"
        >
          <template slot="error-message">
            <span v-if="!$v.formData.firstNameAndLastName.required">Field is required</span>
          </template>
        </BaseInput>
        <BaseInput
          v-if="showInputQuestion"
          class="form-group"
          :placeholder="labelSecretQuestion"
          :hasError="$v.userSecretAnswer.$error"
          v-model="userSecretAnswer"
          maxlength="25"
        >
          <template slot="error-message">
            <span v-if="!$v.userSecretAnswer.required">Field is required</span>
          </template>
        </BaseInput>
        <div v-if="showInputQuestion" class="form-group form-group_submit">
          <button
            class="button button_w-100 button_theme-default button_size-m"
            @click.prevent="confirmSecretAnswer"
          >
            Confirm Secret Answer
          </button>
        </div>
        <div v-else class="form-group form-group_submit">
          <button
            class="button button_w-100 button_theme-default button_size-m"
            @click.prevent="confirmData"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
    <ChangeUserDetailsForm
      v-else
      :old-email="formData.email"
      @set-loading="setLoading"
    >
    </ChangeUserDetailsForm>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import secretQuestions from '@constants/secretQuestions';
import ChangeUserDetailsForm from '@components/UserDetailsComponents/ChangeUserDetailsForm.vue';

const mustBeCool = (emailValid) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(emailValid).toLowerCase());
};

const { required } = require('vuelidate/lib/validators');

export default {
  name: 'ConfirmUserDetailsForm',
  mixins: [validationMixin],
  components: {
    ChangeUserDetailsForm,
  },
  validations: {
    formData: {
      // previousPhoneNumber: {
      //   required,
      // },
      email: {
        required,
        mustBeCool,
      },
      firstNameAndLastName: {
        required,
      },
    },
    userSecretAnswer: {
      required,
    },
  },
  watch: {
    userSecretAnswer() {
      this.userSecretAnswer = this.userSecretAnswer.replace(/\s+/gi, '');
    },
  },
  data: () => ({
    showInputQuestion: false,
    formData: {
      // previousPhoneNumber: null,
      email: null,
      firstNameAndLastName: null,
    },
    secretAnswerId: null,
    userSecretAnswer: null,
    labelSecretQuestion: null,
    showChangeData: false,
    loading: false,
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
    },
    async confirmData() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        this.loading = true;
        const data = {
          firstName: this.formData.firstNameAndLastName.split(' ')[0],
          lastName: this.formData.firstNameAndLastName.split(' ')[1],
          // previousPhone: this.formData.previousPhoneNumber,
          email: this.formData.email,
        };

        try {
          const result = await this.$api.auth.confirmDataForChangeDetails(data);
          this.showInputQuestion = true;
          this.secretAnswerId = result;
          this.labelSecretQuestion = secretQuestions[result].question;
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      }
    },
    async confirmSecretAnswer() {
      this.$v.userSecretAnswer.$touch();
      if (!this.$v.userSecretAnswer.$invalid) {
        this.loading = true;
        const data = {
          // previousPhone: this.formData.previousPhoneNumber,
          email: this.formData.email,
          secretAnswer: this.userSecretAnswer,
        };

        try {
          await this.$api.auth.confirmSecretAnswer(data);
          this.showChangeData = true;
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .change-details-form input {
    background-color: white;
  }
</style>
