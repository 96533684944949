<template>
  <div>
    <h1 class="h4 text-center mb-4">Enter security code</h1>
    <div class="text text-center mb-6">
      Please check your email in the inbox or Spam folder for your security code.
    </div>
    <form class="form">
      <BaseInput
        class="form-group input_enter_code"
        :hasError="$v.code.$error"
        placeholder="Enter code"
        v-model="code"
      >
        <template slot="error-message">
          <span v-if="!$v.code.required">Field is required</span>
        </template>
      </BaseInput>
      <div class="form-group form-group_submit">
        <button
          class="form button button_w-100 button_theme-default button_size-m"
          @click.prevent="submit"
        >
          Continue
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

const { required } = require('vuelidate/lib/validators');

export default {
  name: 'EmailConfirmation',
  mixins: [validationMixin],
  props: {
    objectRequest: {
      types: Object,
    },
  },
  validations: {
    code: {
      required,
    },
  },
  data: () => ({
    code: null,
  }),
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.objectRequest.smsCode = this.code;
        this.$api.auth.updateUserDetails(this.objectRequest).then(() => {
          this.$router.push({ path: '/login' });
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
